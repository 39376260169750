@use "./theme.scss" as theme;

main.signin__page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: theme.$ql-blue-080;
}

.saved__id {
  padding-left: 4px;
}

section.signin__section {
  min-width: 380px;
  padding: 40px 24px;
  border-radius: 10px;
  background-color: theme.$ql-white-040;

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 36px;
  }

  .symbol {
    width: 60px;
  }

  .wordmark {
    width: 150px;
  }

  .message_fail {
    padding-top: 0;
    padding-bottom: 10px;
    font-size: 12px;
    font-weight: bold;
    color: var(--ql-caution);
  }
}

main.signin__page {
  background-color: #98c9fe;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.saved__id {
  padding-left: 4px;
}

section.signin__section {
  background-color: #fff6;
  border-radius: 10px;
  min-width: 380px;
  padding: 40px 24px;
}

section.signin__section .logo {
  justify-content: center;
  align-items: center;
  margin-bottom: 36px;
  display: flex;
}

section.signin__section .symbol {
  width: 60px;
}

section.signin__section .wordmark {
  width: 150px;
}

section.signin__section .message_fail {
  color: var(--ql-caution);
  padding-top: 0;
  padding-bottom: 10px;
  font-size: 12px;
  font-weight: bold;
}
/*# sourceMappingURL=index.f8a47c10.css.map */
